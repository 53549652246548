import { Box, Grid, ResponsiveContext } from "grommet"
import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageOverlay = ({ children, gap, margin, pad, height, width }) => {
  const panel = {}
  React.Children.map(children, (child, index) => {
    panel[child.type.displayName] = child
  })
  const { Image, Text } = panel
  const size = React.useContext(ResponsiveContext)

  return (
    <Box height={size === "small" ? "medium" : "large"}>
      <Grid
        fill={true}
        rows={["auto"]}
        columns={["auto"]}
        areas={[{ name: "area", start: [0, 0], end: [0, 0] }]}
        gap={gap}
        margin={margin}
        pad={pad}
        width={width}
        height={height}
      >
        {Image}
        {Text}
      </Grid>
    </Box>
  )
}

const Image = ({
  image,
  alt = "",
  imgStyle = {},
  style = {},
  gap,
  justify,
  align,
  margin,
  pad,
}) => {
  const _image = getImage(image)
  return (
    <Box
      gridArea="area"
      fill={true}
      gap={gap}
      justify={justify}
      align={align}
      margin={margin}
      pad={pad}
    >
      <GatsbyImage
        image={_image}
        alt={alt}
        imgStyle={imgStyle}
        style={{ height: "100%", width: "100%", ...style }}
      />
    </Box>
  )
}

ImageOverlay.Image = Image
Image.displayName = "Image"

const Text = ({ children, gap, justify, align, pad }) => {
  return (
    <Box
      fill={true}
      gridArea="area"
      style={{ zIndex: 3 }}
      gap={gap || "xsmall"}
      justify={justify || "center"}
      align={align || "center"}
      pad={pad}
    >
      {children}
    </Box>
  )
}

ImageOverlay.Text = Text
Text.displayName = "Text"

export default ImageOverlay
