import React from "react"
import { Box, ResponsiveContext, Text } from "grommet"

import { getImage } from "gatsby-plugin-image"
import Avatar from "./common/Avatar"
import Anchor from "./common/Anchor"

const AuthorCard = ({ author, path_prefix }) => {
  const image = getImage(author.picture.localFile)
  const size = React.useContext(ResponsiveContext)

  return (
    <Box
      direction="row-responsive"
      gap="large"
      pad={
        size === "small"
          ? { vertical: "large", horizontal: "medium" }
          : "medium"
      }
      border={size === "small" ? undefined : "between"}
      width="large"
    >
      <Box width={size === "small" ? "100%" : "25%"} align="center">
        <Avatar image={image} alt="" size="large" />
      </Box>
      <Box align={size === "small" ? "center" : undefined}>
        <Text weight="bold" size="small">
          {author.name}
        </Text>
        <Text size="small">{author.bio}</Text>
        <Anchor
          label={
            <Text size="xsmall">{`See More Articles By ${author.name}`}</Text>
          }
          href={`/${path_prefix}/${author.slug}/`}
          size="xsmall"
        />
      </Box>
    </Box>
  )
}

export default AuthorCard
