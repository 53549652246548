import React from "react"
import { Box, ResponsiveContext, Text } from "grommet"

import { getImage } from "gatsby-plugin-image"
import Avatar from "./common/Avatar"
import Anchor from "./common/Anchor"

const AuthorCard = ({ author, date }) => {
  const image = getImage(author.picture.localFile)
  var published = new Date(date)

  return (
    <Box
      width="xsmall"
      direction="row"
      gap="medium"
      width="large"
      justify="center"
      align="center"
    >
      <Box style={{ flexShrink: 9 }}>
        <Avatar image={image} alt="" size="medium" />
      </Box>

      <Box style={{ flexShrink: 3 }}>
        <Text weight="bold" size="small">
          {author.name}
        </Text>
        <Text size="small">{published.toDateString()}</Text>
      </Box>
    </Box>
  )
}

export default AuthorCard
