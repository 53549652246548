import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import { Heading, Text, Box, ResponsiveContext } from "grommet"
import Markdown from "../../../components/common/Markdown"
import ImageOverlay from "../../../components/ImageOverlay"
import AuthorCard from "../../../components/AuthorCard"
import SmallAuthorCard from "../../../components/SmallAuthorCard"
import Dynamic from "../../../components/Dynamic"
import Anchor from "../../../components/common/Anchor"
import PageLayout from "../../../components/Layouts/PageLayout"

export const query = graphql`
  query($slug: String!, $locale: String!) {
    strapiArticle(slug: { eq: $slug }, locale: { eq: $locale }) {
      seo {
        id
        noIndex
        metaDescription
        metaTitle
        shareImage {
          width
          height
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
      components
      localMarkdownImages {
        childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder: TRACED_SVG
            formats: [AUTO, WEBP, AVIF]
          )
        }
        url
      }
      authors {
        id
        bio
        email
        slug
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 100
                placeholder: TRACED_SVG
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
        name
      }
      categories {
        created_at
        id
        name
        slug
        updated_at
        published_at
      }
      content
      created_at
      description
      image {
        localFile {
          url
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              placeholder: TRACED_SVG
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
      slug
      updated_at
      published_at
      title
    }
    strapiAuthorIndex(locale: { eq: $locale }) {
      path_prefix
    }
    strapiCategoryIndex(locale: { eq: $locale }) {
      path_prefix
    }
  }
`

const CategoryChip = ({ category: { name, slug, ...rest }, path_prefix }) => {
  return (
    <Box
      as={Anchor}
      background="brand"
      style={{ opacity: "80%" }}
      pad={{ vertical: "xsmall", horizontal: "small" }}
      round="large"
      href={`/${path_prefix}/${slug}`}
    >
      <Text size="xsmall">{name}</Text>
    </Box>
  )
}

const ArticlePage = ({
  data: {
    strapiArticle: {
      title,
      components,
      content,
      description,
      image: { localFile },
      localMarkdownImages,
      categories,
      authors,
      updated_at,
    },
    strapiAuthorIndex: { path_prefix: author_path_prefix },
    strapiCategoryIndex: { path_prefix: category_path_prefix },
  },
}) => {
  const image = getImage(localFile)
  return (
    <PageLayout gap="none">
      <Box
        pad={{ bottom: "large", horizontal: "small" }}
        width={"large"}
        gap="medium"
        align="center"
      >
        <Heading level={1} textAlign="center" margin="none">
          {title}
        </Heading>
        <GatsbyImage image={image} alt="" />
        <Box width="600px">
          <Text
            textAlign="center"
            margin={{ horizontal: "medium" }}
            size="small"
          >
            <em>{description}</em>
          </Text>
        </Box>
        <Box>
          {authors.map(author => (
            <SmallAuthorCard
              author={author}
              date={updated_at}
              path_prefix={author_path_prefix}
              key={author.name}
              small
            />
          ))}
        </Box>
        <Box gap="medium" direction="row" wrap="true">
          {categories.map(category => (
            <CategoryChip
              category={category}
              path_prefix={category_path_prefix}
              key={category.slug}
            />
          ))}
        </Box>
        <Box width="70%">
          <hr style={{ width: "70%", opacity: "30%" }} />
        </Box>
      </Box>
      <PageLayout.Content
        pad={{ top: "none", horizontal: "large", buttom: "large" }}
        align="center"
      >
        <Box
          pad={{ horizontal: "small", bottom: "xlarge" }}
          style={{ textAlign: "justify" }}
          width="large"
        >
          <Markdown
            localImages={localMarkdownImages}
            textAlign={{
              h1: "center",
              h2: "center",
            }}
            textMargin={{
              ol: "xsmall",
            }}
          >
            {content}
          </Markdown>
        </Box>
        <Box
          border={{ color: "brand", size: "small" }}
          round="large"
          width="large"
          align="center"
        >
          <Dynamic sections={components} localImages={[]} />
        </Box>
        <Box width="70%" margin={"large"} align="center">
          <hr style={{ width: "70%", opacity: "30%" }} />
          <Box>
            {authors.map(author => (
              <AuthorCard
                author={author}
                path_prefix={author_path_prefix}
                key={author.name}
              />
            ))}
          </Box>
        </Box>
      </PageLayout.Content>
      {/* 
      <ImageOverlay width="large" pad={{ bottom: "large" }}>
        <ImageOverlay.Image
          image={localFile}
          alt=""
          imgStyle={{
            filter: "brightness(65%)",
          }}
        ></ImageOverlay.Image>
        <ImageOverlay.Text>
          <Heading
            level={1}
            size={size !== "small" ? "large" : "small"}
            textAlign="center"
            margin="medium"
            color="text-contrast"
          >
            {title}
          </Heading>
          {size !== "small" && (
            <Text color="text-contrast" textAlign="center" margin="large">
              <em>{description}</em>
            </Text>
          )}
        </ImageOverlay.Text>
      </ImageOverlay>
      {size === "small" && (
        <Box margin={{ bottom: "large" }} gap="large">
          <Text textAlign="center" size="small">
            {description}
          </Text>
        </Box>
      )} */}
    </PageLayout>
  )
}

ArticlePage.propTypes = {}

export default ArticlePage
